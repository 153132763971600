




import { Component, Vue } from 'vue-property-decorator'
import { Logs } from '@/api'

@Component({
  components: {
    LogsTable: () => import('./LogsTable.vue')
  }
})
export default class UserDonationLogs extends Vue {
  data = []

  async mounted() {
    const userId = this.$route.params.id
    const donationLogs = userId
      ? await Logs.getUserDonationLogsById(userId)
      : await Logs.getDonationLogs()
    
    this.data = donationLogs.map((e: any) => {
      return {
        donationId: e.id,
        date: new Date(e.dateTime).toUTCString(),
        money: e.money,
        coinsReceived: e.eCurrency,
        paymentMethod: e.type
      }
    })
  }
}
